<script setup>
import { ref } from "@vue/reactivity";
import Logo from "../Logo.vue";
import NavbarMenuButton from "../NavbarMenuButton.vue";
import NavbarMenuDropdown from "../NavbarMenuDropdown.vue";
import NavbarMenuDropdown2 from "../NavbarMenuDropdown2.vue";
import BlackBG from "../BlackBG.vue";

const isDrawerNavbarVisible = ref(false);
const toggleDrawerNavbarVisible = () => {
  isDrawerNavbarVisible.value = !isDrawerNavbarVisible.value;
  return;
};
</script>
<template>
  <div class="fixed top-0 right-0 left-0 w-full z-10 bg-white border-0 border-b border-solid">
    <div class="flex justify-between pt-[30px] pb-[10px] max-w-tNavbar mx-auto">
      <Logo />
      <div class="flex items-end t850Max:hidden">
        <NavbarMenuButton :isActive="true" url="#">{{ $t('home') }}</NavbarMenuButton>
        <NavbarMenuButton :isActive="false" url="#service">{{ $t('service') }}</NavbarMenuButton>
        <NavbarMenuButton :isActive="false" url="#product">{{ $t('product') }}</NavbarMenuButton>
        <NavbarMenuButton :isActive="false" url="#comments">{{ $t('testimonials') }}</NavbarMenuButton>
        <NavbarMenuDropdown :isActive="false">{{ $t('Language') }}</NavbarMenuDropdown>
      </div>
      <button
        @click="toggleDrawerNavbarVisible()"
        class="t850Min:hidden t850Max:flex"
      >
        <svg viewBox="0 0 100 80" width="40" height="25">
          <rect width="80" height="7"></rect>
          <rect y="30" width="80" height="7"></rect>
          <rect y="60" width="80" height="7"></rect>
        </svg>
      </button>
    </div>

    <!-- DRAWER NAVBAR BEGIN -->
    <div
      class="
        flex flex-col
        w-[70vw]
        h-[100vh]
        fixed
        bg-tWhite
        top-0
        right-0
        transform
        t850Min:hidden
        transition
        duration-350
        z-DrawerNavbar
      "
      :class="{ 'translate-x-[100%]': !isDrawerNavbarVisible }"
    >
      <NavbarMenuButton class="py-[20px] border-b-[3px]" :isActive="true" url="#">{{ $t('home') }}</NavbarMenuButton>
      <NavbarMenuButton class="py-[20px] border-b-[3px]" :isActive="false" url="#service">{{ $t('service') }}</NavbarMenuButton>
      <NavbarMenuButton class="py-[20px] border-b-[3px]" :isActive="false" url="#product">{{ $t('product') }}</NavbarMenuButton>
      <NavbarMenuButton class="py-[20px] border-b-[3px]" :isActive="false" url="#comments">{{ $t('testimonials') }}</NavbarMenuButton>
      <NavbarMenuDropdown2 class="py-[20px] border-b-[3px]" :isActive="false">{{ $t('Language') }}</NavbarMenuDropdown2>

    </div>
    <BlackBG
      @click="toggleDrawerNavbarVisible()"
      class="t850Min:hidden"
      v-show="isDrawerNavbarVisible"
    />
    <!-- DRAWER NAVBAR END -->
  </div>
</template>
