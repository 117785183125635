export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首頁"])},
  "Sum up Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息整合"])},
  "home-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們提供以數據為驅動，提供數據處理和分析的各種服務，從數據中獲取有價值的規律幫助客戶做出各種精準、有效的商業決策。"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務"])},
  "Our services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們的服務"])},
  "Cloud Migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雲平臺遷移"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切換語言"])},
  "service-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們提供各種不同的技術服務，幫助不同企業或個人達到數據挖掘、分析、使用。提高工作效率，增加系統彈性，減少維護預算。"])},
  "cloud-migrate-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶經常有不同原因需要轉換雲平臺，比如從極光推送換成Firebase，高德地圖換成谷歌地圖。阿裏雲換成亞馬遜雲"])},
  "IoT Development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物聯網開發"])},
  "iot-dev-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們有完善的流程可以幫助你的物聯網產品從構思，樣品開發，以及投入批量生產"])},
  "Streaming solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒體流方案"])},
  "streaming-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒體流點播解決方案，支持高並發，播放流暢，部署簡單，預算低，用多少流量支付多少費用"])},
  "Data analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數據分析"])},
  "data-analysis-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整合不同來源的數據，包括純文本，json，word，pdf甚至圖片文件，進行匯總，歸類，重新入庫"])},
  "DevOps Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["運維支持"])},
  "devops-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是每個組織都有預算雇傭全職運維工程師，我們可以幫助你建立標準的開發運維流程"])},
  "Machine learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機器學習"])},
  "machine-learning-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幫助客戶搭建機器學習模型，訂做相關算法和采集訓練數據，連接數據源以及結果輸出"])},
  "Welinju": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["微鄰居"])},
  "Our products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們的產品"])},
  "product-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們的服務幫助了許多機構獲得了成功，我們同時也運營和不斷開發一些自己的平臺和產品，以下是兩款我們目前主要維護和開發的產品"])},
  "Launched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已發布"])},
  "launched-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["微鄰居是我們開發的一款以鄰居為基礎的社交網絡App，幫助鄰居間互通信息，建立信任，相互照看從而達到提高社區安全的目的。"])},
  "one-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一鍵雲求助"])},
  "In Development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開發中"])},
  "button-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這是一款幫助老年人在需要幫助時發送通知或警報的物聯網設備。傳統的幫助按鈕只向呼叫中心撥打電話，在呼叫高峰時具有連線的瓶頸。而我們的設備是基於雲架構，所有通知將放入隊列，沒有人會被錯過。通知也可發送給社區成員。"])},
  "testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶評價"])},
  "comment-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客戶對我們的評價"])},
  "CTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技術總監"])},
  "Product Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品經理"])},
  "comment-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通過雲平臺的遷移服務，幫助我們節省了每月幾千元的推送服務賬單，而且用戶體驗更加友好，性能更加穩定。"])},
  "comment-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從來都沒有想過部署我們自己的視頻流服務，不需要大批硬件和網絡的投資就可以擁有企業級的視頻點播功能。"])},
  "Contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯系我們"])},
  "contact-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你對我們的服務有任何疑問，或者有可能的合作意向，請不要猶豫，馬上聯系我們"])}
}