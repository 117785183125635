export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "Sum up Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息整合"])},
  "home-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们提供以数据为驱动，提供数据处理和分析的各种服务，从数据中获取有价值的规律帮助客户做出各种精准、有效的商业决策。"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务"])},
  "Our services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的服务"])},
  "Cloud Migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["云平台迁移"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换语言"])},
  "service-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们提供各种不同的技术服务，帮助不同企业或个人达到数据挖掘、分析、使用。提高工作效率，增加系统弹性，减少维护预算。"])},
  "cloud-migrate-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户经常有不同原因需要转换云平台，比如从极光推送换成Firebase，高德地图换成谷歌地图。阿里云换成亚马逊云"])},
  "IoT Development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物联网开发"])},
  "iot-dev-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们有完善的流程可以帮助你的物联网产品从构思，样品开发，以及投入批量生产"])},
  "Streaming solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒体流方案"])},
  "streaming-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒体流点播解决方案，支持高并发，播放流畅，部署简单，预算低，用多少流量支付多少费用"])},
  "Data analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据分析"])},
  "data-analysis-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整合不同来源的数据，包括纯文本，json，word，pdf甚至图片文件，进行汇总，归类，重新入库"])},
  "DevOps Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["运维支持"])},
  "devops-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不是每个组织都有预算雇佣全职运维工程师，我们可以帮助你建立标准的开发运维流程"])},
  "Machine learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机器学习"])},
  "machine-learning-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助客户搭建机器学习模型，订做相关算法和采集训练数据，连接数据源以及结果输出"])},
  "Welinju": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["微邻居"])},
  "Our products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的产品"])},
  "product-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的服务帮助了许多机构获得了成功，我们同时也运营和不断开发一些自己的平台和产品，以下是两款我们目前主要维护和开发的产品"])},
  "Launched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发布"])},
  "launched-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["微邻居是我们开发的一款以邻居为基础的社交网络App，帮助邻居间互通信息，建立信任，相互照看从而达到提高社区安全的目的。"])},
  "one-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一键云求助"])},
  "In Development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开发中"])},
  "button-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是一款帮助老年人在需要帮助时发送通知或警报的物联网设备。传统的帮助按钮只向呼叫中心拨打电话，在呼叫高峰时具有连线的瓶颈。而我们的设备是基于云架构，所有通知将放入队列，没有人会被错过。通知也可发送给社区成员。"])},
  "testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户评价"])},
  "comment-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户对我们的评价"])},
  "CTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技术总监"])},
  "Product Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品经理"])},
  "comment-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过云平台的迁移服务，帮助我们节省了每月几千元的推送服务账单，而且用户体验更加友好，性能更加稳定。"])},
  "comment-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从来都没有想过部署我们自己的视频流服务，不需要大批硬件和网络的投资就可以拥有企业级的视频点播功能。"])},
  "Contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
  "contact-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你对我们的服务有任何疑问，或者有可能的合作意向，请不要犹豫，马上联系我们"])}
}