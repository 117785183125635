<template>
  <div
    class="
      flex flex-col
      w-[350px]
      h-[354px]
      bg-tWhite
      rounded-[20px]
      shadow-Card1
    "
  >
    <div class="grid justify-items-center mt-[30px]"><slot name="icon" /></div>
    <div class="grid justify-items-center mt-[10px] font-bold text-[24px] leading-[56px]">
      <slot name="title" />
    </div>
    <div
      class="ml-[38px] max-w-[266px] font-light leading-[28px] text-tLightGray"
    >
      <slot name="description" />
    </div>
  </div>
</template>
