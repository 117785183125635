import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
//import './assets/tailwind.css'
import 'flowbite';
import i18n from './i18n'


if(process.env.VUE_APP_ENV == 'prod'){
    if(location.hostname.match(/^www\./i)){
        // hardcode to use sumfo.com
        window.location.href = 'https://sumfo.com'
    }
}

createApp(App).use(i18n).mount("#app");
