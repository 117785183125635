export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Sum up Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum up Information"])},
  "home-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide data-driven, data-processing and analysis services to help customers make accurate and effective business decisions by discovering valuable pattern or rule from data."])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "Our services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services"])},
  "Cloud Migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud Migration"])},
  "service-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide a variety of technical services to help different organizations or individuals to achieve data mining, analysis and use. Improve work efficiency, increase system flexibility, and reduce maintenance budget."])},
  "cloud-migrate-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers often need to switch cloud platforms for different reasons, such as switching from JPush to Firebase"])},
  "IoT Development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IoT Development"])},
  "iot-dev-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a complete process to help you get your IoT idea from prototype, sample development, and bring to manufacture"])},
  "Streaming solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming solution"])},
  "streaming-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media streaming on demand solution, support high concurrency, playback, simple deployment, low budget, only pay what you use"])},
  "Data analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data analysis"])},
  "data-analysis-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process data from different sources, including plain text, JSON, Word, PDF and even image files, summarize, categorize and re-store"])},
  "DevOps Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps Support"])},
  "devops-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all organization have bugget to hire fulltime DevOps, we can help you setup your SDLC"])},
  "Machine learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine learning"])},
  "machine-learning-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help customers build machine learning models, develop relevant algorithms, connect data sources and output results"])},
  "Welinju": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welinju"])},
  "Our products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our products"])},
  "product-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services have helped many organizations succeed, We also maintain and continue developing our own platforms and products, The following are two major products we are building"])},
  "Launched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launched"])},
  "launched-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welinju is a neighbor-based social network App we built, This app helps neighbors to share information, build trust and take care of each other and build a safe neighbor community."])},
  "one-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One button help whistle"])},
  "In Development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Development"])},
  "button-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an IoT device help senior to send out notification or alert when they need help. Tranditional senior help button only send phone call to call center, It has bottom neck when call spike. Our device is cloud base, all notification will put into queue, no one will be missed. Notification can also be assigned to community members."])},
  "testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonials"])},
  "comment-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What our customer are saying"])},
  "CTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTO"])},
  "Product Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Manager"])},
  "comment-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cloud migration service helps us save thousands of dollars on the push notification bill every month, and the user experience is more friendly and the performance is more stable."])},
  "comment-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never dreamed of hosting our own video streaming service, enterprise-level Video-On-Demand capabilities that didn't require a lot of hardware and network investment."])},
  "Contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "contact-detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please do not hesitate to contact us if you have any questions about our services or if you are interested in working with us"])}
}